"use client";

import { useState } from "react";
import { useRouter } from "next/navigation";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useLogin } from "@/hooks/useLogin";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";

const formSchema = z.object({
  email: z.string().min(1, "L'email est obligatoire."),
  password: z.string().min(1, "Le mot de passe est obligatoire."),
});

export default function LoginPage() {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const { loginUser } = useLogin();
  const router = useRouter();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setLoading(true);

    loginUser(data.email, data.password)
      .then((res) => {
        setLoading(false);
        router.push("/app/dashboard");
      })
      .catch((e) => {
        setLoading(false);
        toast({
          variant: "destructive",
          title: "Il y a eu un problème !",
          description: "Un problème est survenu avec votre demande.",
        });
      });
  };

  return (
    <>
      <div className="grid gap-2">
        <h1 className="text-2xl font-bold">Se connecter</h1>
        <p className="text-sm text-muted-foreground">
          Saisissez votre email pour vous connecter.
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-4 py-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      disabled={loading}
                      placeholder="hello@email.com"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mot de passe</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      disabled={loading}
                      placeholder="Mot de passe"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Button type="submit" className="w-full mt-2" disabled={loading}>
            {loading ? "Connexion..." : "Se connecter"}
          </Button>

          <div className="mt-4 text-center text-sm">
            <Link href="/forgot-password" className="hover:underline">
              Mot de passe oublié ?
            </Link>
          </div>
        </form>
      </Form>

      {/* <div className="mt-4 text-center text-sm">
        Vous n&apos;avez pas encore de compte ?{" "}
        <Link href="signup" className="underline">
          Créer une compte
        </Link>
      </div> */}
    </>
  );
}
